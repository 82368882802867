.signUpContainer {
    background-image: url("../../images/login2.jpg"), linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    min-height: 100vh;
    min-width: 100vh;
}

.signupBox {
    border: 1px solid black;
    background: #262834;
}

.fieldsContainer {
    margin: 30px;
}

.signupTitle {
    color: white;
}

li {
    font-family: sans-serif;
  font-size: 18px;
  display: list-item;
  
}

#header1{
    font-family: sans-serif;
    color:darkslateblue;
}

.navbar-default{
  background-color:white;
}

p{
  font-family: sans-serif;
  font-size: 18px;
}

a{
  color: blue;
}
@import url('https://fonts.googleapis.com/css?family=Stylish');
.custom-btn{
    background-color: #fff;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.custom-btn:hover{
    background-color: #fff !important;
}
.custom-btn:focus{
    outline: 0;
    background-color: #fff !important;
}

.edriverText{
    font-family: 'Stylish', sans-serif;
    font-size: 30px;
}

.navBarTextColor {
    text-decoration: none;
    color: #656464;
}

.navBarTextColor:link {
    text-decoration: none;
    color: #656464;
}

.navBarTextColor:visited {
    text-decoration: none;
    color: #656464;
}

.navBarTextColor:hover {
    text-decoration: none;
    color: #7d7c7c;
}

.navBarTextColor:active {
    text-decoration: none;
    color: #656464;
}
